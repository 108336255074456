
import { defineComponent, ref } from 'vue'
import GettingStarted from '@/components/pages/gettingStarted/GettingStarted.vue'
import LocalNumber from '@/components/pages/gettingStarted/gettingStartedBlocks/LocalNumber.vue'
import CpassAccount from '@/components/pages/gettingStarted/gettingStartedBlocks/CpassAccount.vue'
import Whatsapp from '@/components/pages/gettingStarted/gettingStartedBlocks/Whatsapp.vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import GettingStartedFaqTollFree from '@/components/pages/gettingStarted/GettingStartedFaqTollFree.vue'
import GettingStartedFaqDlc from '@/components/pages/gettingStarted/GettingStartedFaqDlc.vue'
import GettingStartedFaqCpaas from '@/components/pages/gettingStarted/GettingStartedFaqCpaas.vue'
import GettingStartedFaqWhatsapp from '@/components/pages/gettingStarted/GettingStartedFaqWhatsapp.vue'

export default defineComponent({
  components: {
    GettingStarted,
    LocalNumber,
    CpassAccount,
    Whatsapp,
    FaqBlock,
    GettingStartedFaqTollFree,
    GettingStartedFaqDlc,
    GettingStartedFaqCpaas,
    GettingStartedFaqWhatsapp,
  },
  setup() {
    const gettingStartedRef = ref()
    const tabs = ref([
      {
        name: 'toll-free',
        label: 'Toll-free numbers',
      },
      {
        name: 'dlc',
        label: '10DLC numbers',
      },
      {
        name: 'cpaas',
        label: 'CPaaS integrations',
      },
      {
        name: 'whatsapp',
        label: 'WhatsApp',
      },
    ])

    const currentTab = ref(tabs.value[0].name)

    const tollFreeNumber = () => {
      currentTab.value = 'toll-free'
      gettingStartedRef.value.scrollTo()
    }
    const localNumber = () => {
      currentTab.value = 'dlc'
      gettingStartedRef.value.scrollTo()
    }

    return {
      gettingStartedRef,
      tabs,
      currentTab,
      tollFreeNumber,
      localNumber,
    }
  },
})
